import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import style from './Footer.css'

class Footer extends Component {
  render () {
    return (
      <footer className='footer has-cards'>
        <div className='container'>
          <hr />
          <div className='row align-items-center justify-content-md-between'>
            <div className='col-md-4 col-lg-4 aos-init aos-animate'>
              <div className='copyright'>© 2019 Leader Abogados .</div>
            </div>
            <div className='col-md-8 col-lg-8 aos-init aos-animate'>
              <ul className='nav nav-footer justify-content-end'>
                <li className='nav-item'>
                  <a href='#' className='nav-link' target='_blank'>
                    Aviso Legal
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='#' className='nav-link' target='_blank'>
                    Política de Privavidad
                  </a>
                </li>
                <li className='nav-item'>
                  <a href='#' className='nav-link' target='_blank'>
                    Política de Cookies
                  </a>
                </li>
                <li className='nav-item'>
                  <span className='nav-link'>
                    {' '}
                    © Todos los derechos reservados
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

// export default Footer
export { Footer }
