import React from "react";
import { Section } from "./styles";

export function HeaderGradient(props) {
  return (
    <Section background={props.background}>
        {props.children}
    </Section>
  );
}
