import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  margin-top: 30px;
`;

export const Header = styled.div`
  display: flex;
  flex: "1";
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  z-index: 1;
`;

export const CardWrapper = styled.div`
  overflow: hidden;
  padding: 0 0 32px;
  margin: 48px auto 0;
  width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  @media all and (max-width: 3000px) {
    width: 700px;
  }
  @media all and (max-width: 1690px) {
    width: 700px;
  }
  @media all and (max-width: 1280px) {
    width: 700px;
  }
  @media all and (max-width: 980px) {
    width: 700px;
  }
  @media all and (max-width: 736px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

export const CardHeader = styled.header`
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const CardHeading = styled.h1`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

export const CardBody = styled.div`
  padding-right: 32px;
  padding-left: 32px;
`;

export const CardFieldset = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
  }
`;

export const CardInput = styled.input`
  padding: 7px 0;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ddd;
  border-left: 0;
  transition: border-bottom-color 0.25s ease-in;

  &:focus {
    border-bottom-color: #312782;
    outline: 0;
  }
`;

export const CardIcon = styled.span`
  color: #666;
  cursor: pointer;
  opacity: .25;
  transition: opacity .25s ease-in;

  &:hover {
    opacity: .95;
  }

  ${props =>
    props.big &&
    css`
      font-size: 26px;
    `}

  ${props =>
    props.eye &&
    css`
      position: absolute;
      top: 8px;
      right: 0;
    `}

  ${props =>
    props.small &&
    css`
      font-size: 14px;
    `}
`;

export const CardButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #312782;
  background-color: ${props =>
    (props.typeButton === "primary" && "#312782") ||
    (props.typeButton === "danger" && "red") ||
    (props.typeButton === "warning" && "yellow")};

  border: 0;
  border-radius: 35px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
`;

export const CardLink = styled.a`
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
  color: #aaa;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: color 0.25s ease-in;

  &:hover {
    color: #777;
  }
`;

export const ListWrapper = styled.div`
  overflow: hidden;
  padding: 0 0 32px;
  margin: 48px auto 0;
  width: 300px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px; */
  @media all and (max-width: 3000px) {
    width: 1500px;
  }
  @media all and (max-width: 1690px) {
    width: 1200px;
  }
  @media all and (max-width: 1280px) {
    width: 1024px;
  }
  @media all and (max-width: 980px) {
    width: 700px;
  }
  @media all and (max-width: 736px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

export const UsersWrapper = styled.ul`
  list-style: none;
  /* width: 100%; */
  padding: 0 0 32px;
  margin: 48px;
`;
export const User = styled.li`
  list-style: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  @media all and (max-width: 736px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
export const UserAvatar = styled.div`
  border-radius: 50%;
  padding: 20px;
  width: 76px;
  height: 76px;
  color: #fff;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  @media all and (max-width: 736px) {
    margin-right: 0;
  }
`;

export const UserTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin: 0;
  padding: 0;
`;
export const UserEmail = styled.a`
  font-size: 16px;
  font-weight: bold;
  color: #1aae6f;
  border-bottom: 2px dashed #1aae6f;
  margin-right: 1rem !important;
  @media all and (max-width: 736px) {
    display: block;
  }
`;
export const UserPhone = styled.a`
  font-size: 16px;
  color: #999;
  @media all and (max-width: 736px) {
    display: block;
  }
`;
export const UserContainer = styled.div`
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 736px) {
    flex-direction: column;

    align-items: center;
    text-align: center;
  }
`;
export const UserActions = styled.div`
  align-items: center;
  display: flex;
`;

export const UserBtn = styled(Link)`
  display: block;
  width: 100%;
  padding: 12px 20;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #312782;
  border: 0;
  border-radius: 35px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);

  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
`;
export const HeaderBtn = styled.button`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 12px 20;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  background-color: #312782;
  border: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  /* float: right; */
  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
`;

export const CardText = styled.p`
  font-size: 14px;
  text-align: center;
`;

export const ListHeader = styled.div`
  padding: 0 0 32px;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
