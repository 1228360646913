import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconUser from "../../stylesheets/assets/icons/user.svg";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import FontAwesome from "react-fontawesome";
import * as ROLES from "../../constants/roles";
import moment from "moment";
import "moment/locale/es";
import { confirmAlert } from "react-confirm-alert"; // Import
import { HeaderGradient } from "../../common/Layout/HeaderGradient";
import { Container } from "../../common/Layout/Container";
import {
  ListWrapper,
  UsersWrapper,
  User,
  UserAvatar,
  UserTitle,
  UserContainer,
  UserPhone,
  UserEmail,
  UserBtn,
  UserActions,
  ListHeader,
  CardFieldset,
  HeaderBtn
} from "./styles";
import DropdownMenu from "../../common/Buttons/Dropdown";

class ComunicadosList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      comunicados: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .comunicados()
      .onSnapshot(snapshot => {
        let comunicados = [];

        snapshot.forEach(doc =>
          comunicados.push({ ...doc.data(), uid: doc.id })
        );

        this.setState({
          comunicados,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onRemoveComunicado = uid => {
    confirmAlert({
      title: "Confirmar eliminar",
      message: "¿Estás seguro que quieres borrar este comunicado.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // delete doc
            this.props.firebase.comunicado(uid).delete();
          }
        },
        {
          label: "No",
          onClick: () =>
            console.log(
              "Click No... todos tenemos derechos a una segunda oportunidad"
            )
        }
      ]
    });
  };
  onEditComunicado = uid => {
    this.props.history.push(`/comunicados/edit/${uid}`);
  };

  render() {
    const menuItems = [
      {
        label: "Crear Comunicado",
        icon: "fa fa-plus",
        onClick: () => this.props.history.push(ROUTES.COMUNICADO_ADD)
      }
    ];
    const { comunicados, loading } = this.state;
    console.log(comunicados);
    return (
      <React.Fragment>
        <main>
          <HeaderGradient background="admin">
            <Container>
              <h1 className="display-3  text-white">
                Comunicados:
                <span>Solo para administradores</span>
              </h1>
            </Container>
          </HeaderGradient>
        </main>
        <Container>
          <ListHeader>
            <h2>Comunicados</h2>

            <HeaderBtn type="button">
              <DropdownMenu items={menuItems} />
            </HeaderBtn>
          </ListHeader>
        </Container>

        <Container>
          {loading && <div>Loading ...</div>}
          <ul className="comunicados">
            {comunicados.map(comunicado => (
              <li key={comunicado.uid} className="col-12 dt-module__list-item">
                <div>
                  <Link to={"/comunicado/" + comunicado.uid}>
                    {comunicado.title}
                  </Link>{" "}
                  {comunicado.editedAt && (
                    <span className="badge badge-info">Editado</span>
                  )}
                  <br />
                  <span className="fechaComunicado">
                    {comunicado.createdAt &&
                      moment(comunicado.createdAt.toDate()).format("LL")}
                  </span>
                </div>

                <div>
                  <button
                    className="btn btn-info miniIcons"
                    onClick={() => this.onEditComunicado(comunicado.uid)}
                  >
                    <FontAwesome name="edit" />
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger miniIcons"
                    onClick={() => {
                      this.onRemoveComunicado(comunicado.uid);
                      ;
                    }}
                  >
                    <FontAwesome name="trash" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
          </Container>
      </React.Fragment>
    );
  }
}

export default withFirebase(ComunicadosList);
