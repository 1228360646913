import React, { Component } from "react";
import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import {
  CardWrapper,
  CardHeader,
  CardHeading,
  CardBody,
  CardFieldset,
  CardInput,
  CardIcon,
  CardButton
} from "./styles";
import FormGroup from "../../common/Form/FormGroup";

class UserEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      usuario: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.state.usuario) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot(snapshot => {
        const usuario = snapshot.data();

        this.setState({
          username: usuario.username,
          email: usuario.email,
          phone: usuario.phone,
          dni: usuario.dni,
          roles: {},
          uid: usuario.uid,
          usuario: snapshot.data(),
          loading: false
        });
      });
  }

  componentWillMount() {}
  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  //   onChange = event => {
  //     this.setState({ username: event.target.value });
  //   };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    ;
  };

  onEditComunicado = (e, usuario) => {
    e.preventDefault();
    const { uid, ...usuarioSnapshot } = usuario;
    console.log(usuario);
    this.props.firebase
      .user(this.props.match.params.id)
      .update({
        ...usuarioSnapshot,
        username: this.state.username,
        email: this.state.email,
        phone: this.state.phone,
        dni: this.state.dni,
        roles: {},
        uid: this.state.uid,
        editedAt: this.props.firebase.fieldValue.serverTimestamp()
      })
      .then(() => {
        this.props.history.push("/usuarios/" + this.props.match.params.id);
      });
  };

  handleChange(event) {

    this.setState({ category: event.target.value });
  }
  render() {
    const {
      username,
      email,
      phone,
      dni,
      torre,
      inmueble,
      garaje,
      roles,
      uid,
      usuario,
      loading
    } = this.state;
    // ;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <React.Fragment>
            {loading && <div>Loading ...</div>}
            {usuario && (
              <form
                onSubmit={event =>
                  this.onEditComunicado(event, this.state.usuario)
                }
              >
                <CardWrapper>
                  <CardHeader>
                    <CardHeading>Editar Usuario</CardHeading>
                  </CardHeader>

                  <CardBody>
                    <CardFieldset>
                      <CardInput
                        placeholder="Usuario"
                        name="username"
                        type="text"
                        value={username}
                        onChange={this.onChange}
                        required
                      />
                    </CardFieldset>

                    <CardFieldset>
                      <CardInput
                        placeholder="Email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.onChange}
                        required
                      />
                    </CardFieldset>

                    <CardFieldset>
                      <CardInput
                        placeholder="Teléfono"
                        name="phone"
                        type="text"
                        value={phone}
                        onChange={this.onChange}
                        required
                      />
                    </CardFieldset>

                    <CardFieldset>
                      <CardInput
                        placeholder="DNI"
                        name="dni"
                        type="text"
                        value={dni}
                        onChange={this.onChange}
                        required
                      />
                    </CardFieldset>

                    <CardFieldset>
                      <CardButton type="submit">Guardar</CardButton>
                    </CardFieldset>
                  </CardBody>
                </CardWrapper>
              </form>
            )}
          </React.Fragment>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(UserEdit);
