import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import MessageList from "./MessageList";
import FileList from "./FileList";

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
      messages: [],
      files: [],
      limit: 5,
      uploadValue: 0,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.onListenForMessages();
    this.onListenForFiles();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy("createdAt", "desc")
      .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach((doc) =>
            messages.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            messages: messages.reverse(),
            loading: false,
          });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };
  onListenForFiles = () => {
    this.setState({ loading: true });

    this.unsubscribeFiles = this.props.firebase
      .files()
      .orderBy("createdAt", "desc")
      // .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach((doc) =>
            files.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            files: files.reverse(),
            loading: false,
          });
        } else {
          this.setState({ files: [], loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeFiles();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().add({
      text: this.state.text,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ text: "" });

    event.preventDefault();
  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.message(message.uid).update({
      ...messageSnapshot,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = (uid) => {
    this.props.firebase.message(uid).delete();
  };

  onNextPage = () => {
    this.setState(
      (state) => ({ limit: state.limit + 5 }),
      this.onListenForMessages
    );
  };

  handleOnChange(event, authUser) {
    const file = event.target.files[0];
    const storageRef = this.props.firebase.picture(file);
    const task = storageRef.put(file);
    this.setState({
      name: file.name,
    });

    task.on(
      "state_changed",
      (snapshot) => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage,
        });
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.setState({
              picture: url,
            });
            this.props.firebase.pictures().add({
              file: url,
              name: this.state.name,
              userId: authUser.uid,
              createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
          });
        task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
        
      }
    );
  }

  render() {
    const { text, messages, loading, files } = this.state;

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            <form onSubmit={(event) => this.onCreateMessage(event, authUser)}>
              <input type="text" value={text} onChange={this.onChangeText} />
              <button type="submit">Enviar </button>
            </form>

            {loading && <div>Loading ...</div>}

            {messages && (
              <MessageList
                authUser={authUser}
                messages={messages}
                onEditMessage={this.onEditMessage}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!messages && <div>No hay mensajes ...</div>}

            {!loading && messages && (
              <button type="button" onClick={this.onNextPage}>
                Mostrar más
              </button>
            )}
            <hr />
            <h4>Documentación</h4>
            <div>
              <progress value={this.state.uploadValue} max="100">
                {this.state.uploadValue} %
              </progress>
              <br />
              <input
                type="file"
                onChange={(event) => this.handleOnChange(event, authUser)}
              />
              <br />
              <img width="90" src={this.state.picture} />
            </div>
            {loading && <div>Loading ...</div>}

            {messages && (
              <FileList
                authUser={authUser}
                files={files}
                // onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!messages && <div>No hay mensajes ...</div>}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Messages);
