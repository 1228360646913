import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes("password");

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div>
                <main>
                  <section className="section section-shaped section-lg">
                    <div className="shape shape-style-1 bg-gradient-default" />
                    <div className="container pt-lg-md">
                      <div className="row justify-content-center">
                        <div className="col-lg-5">
                          <div className="card bg-secondary shadow border-0">
                            <div className="card-body px-lg-5 py-lg-5">
                              <div className="text-center mb-4">
                                <h1 className="h1Box">Usuario Creado</h1>
                              </div>
                              {this.state.isSent ? (
                                <p>
                                  Confirmación de correo electrónico enviada: El
                                  usuario debe revisar sus correos electrónicos
                                  (Incluyendo carpeta de spam) para una
                                  confirmación Correo electrónico que se le ha
                                  enviado. Actualiza esta página para crear un
                                  nuevo usuario.
                                </p>
                              ) : (
                                <p>
                                  Verifique su correo electrónico: verifique sus
                                  correos electrónicos (correo no deseado)
                                  carpeta incluida) para un email de
                                  confirmación o enviar otra confirmación por
                                  correo electrónico.
                                </p>
                              )}
                            </div>
                            <button
                              type="button"
                              className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0"
                              onClick={this.onSendEmailVerification}
                              disabled={this.state.isSent}
                            >
                              Enviar confirmación de Email
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
