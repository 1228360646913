import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { withFirebase } from "../Firebase";
import { getFileExtension } from "../../lib/tools";
import { withAuthorization, withEmailVerification } from "../Session";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const uuid = require("uuidv4");
var FontAwesome = require("react-fontawesome");

toast.configure()


const CreateUserPage = () => (
  <div>
    <main>
      <section className="section section-lg section-shaped">
        <div className="shape shape-style-1 bg-gradient-default" />
        <div className="container pt-lg-md">
          <div className="row justify-content-center">
            <div className="container">
              <h1 className="display-3  text-white">
                Importar usuarios
                <span>
                  Importa múltiples usarios. Solo acepta ficheros .csv
                </span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-lg">
        <div className="container pt-lg-md">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card bg-secondary shadow border-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <CreateUserForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

const INITIAL_STATE = {
  uploadValue: 0,
  file: null
};

class CreateUserFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    });
  };

  onClickHandler = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    axios
      .post("http://localhost:8000/upload", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then(res => {
        // then print response status
        console.log(res.statusText);
      });
  };

  handleOnChange(event) {
    const data = new FormData();
    data.append("file", event.target.files[0]);

    axios
      .post(
        "https://us-central1-primavera-pi.cloudfunctions.net/create_user/csv",
        data
      )
      .then(function(response) {
        console.log(response);
        toast.success("Se están importando los usuario!");
      })
      .catch(function(error) {
        console.log(error);
        toast.error("Ha sucesido un error");
      });
  }

  render() {
    const { error } = this.state;

    return (
      <div className="container">
        <div>
          <br />
          <input
            id="file"
            name="file"
            type="file"
            ref={ref => (this.uploadFile = ref)}
            style={{ display: "none" }}
            onChange={event => this.handleOnChange(event)}
          />

          <button
            className="btn btn-info btn-icon mb-3 mb-sm-0 btn-block"
            onClick={e => this.uploadFile.click()}
          >
            Subir Fichero <FontAwesome name="cloud-upload" />
          </button>
          <br />
          {this.state.file}
        </div>

        {error && <p>{error.message}</p>}
      </div>
    );
  }
}

const CreateUserForm = withRouter(withFirebase(CreateUserFormBase));

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(CreateUserPage);
// export default CreateUserPage;

export { CreateUserForm };
