import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { withFirebase } from "../Firebase";
import { getFileExtension } from "../../lib/tools";
import { withAuthorization, withEmailVerification } from "../Session";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CardWrapper,
  CardHeader,
  CardHeading,
  CardBody,
  CardFieldset,
  CardButton,
  CardText
} from "./styles";
var FontAwesome = require("react-fontawesome");

toast.configure();

const CreateUserPage = () => (
  <CardWrapper>
    <UserImport />
  </CardWrapper>
);

const INITIAL_STATE = {
  uploadValue: 0,
  file: null
};

class UserImport extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    });
  };

  onClickHandler = () => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    axios
      .post("http://localhost:8000/upload", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then(res => {
        // then print response status
        console.log(res.statusText);
      });
  };

  handleOnChange(event) {
    const data = new FormData();
    data.append("file", event.target.files[0]);

    axios
      .post(
        "https://us-central1-primavera-pi.cloudfunctions.net/create_user/csv",
        data
      )
      .then(function(response) {
        console.log(response);
        toast.success("Se están importando los usuario!");
      })
      .catch(function(error) {
        console.log(error);
        toast.error("Ha sucesido un error");
      });
  }

  render() {
    const { error } = this.state;

    return (
      <CardWrapper>
        <CardHeader>
          <CardHeading>Importar usuarios</CardHeading>
        </CardHeader>

        <CardBody>
          <div>
            <br />
            <input
              id="file"
              name="file"
              type="file"
              ref={ref => (this.uploadFile = ref)}
              style={{ display: "none" }}
              onChange={event => this.handleOnChange(event)}
            />
            <CardFieldset>
            <CardText>Recuerda que debes subir un fichero csv. (username, dni, email, phone, torre, inmueble, garaje) </CardText>
          </CardFieldset>
            <CardFieldset>
              <CardButton type="button" onClick={e => this.uploadFile.click()}>
                <FontAwesome name="cloud-upload" /> Subir Fichero
              </CardButton>
            </CardFieldset>
            {/* <button
            className="btn btn-info btn-icon mb-3 mb-sm-0 btn-block"
            onClick={e => this.uploadFile.click()}
          >
            Subir Fichero 
          </button> */}
            <br />
            {this.state.file}
          </div>

          
          {error && <p>{error.message}</p>}
        </CardBody>
      </CardWrapper>
    );
  }
}

export default withFirebase(UserImport);
