import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import FileList from "./FileList";
import { getFileExtension } from "../../lib/tools";
import { v4 as uuidv4 } from "uuid";
import "./documentos.css";
// import Dropzone from "react-dropzone";

class DocumentosPrivados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
      files: [],
      limit: 5,
      uploadValue: 0,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.onListenForFiles();
  }

  onListenForFiles = () => {
    this.setState({ loading: true });

    var authUser = this.props.authUser;
    this.unsubscribeFiles = this.props.firebase
      .filesPrivate()
      // .where("userId", "==", "df961zlcdif63BSykrBB0FGrGDZ2")
      .where("userId", "==", authUser.uid)
      .orderBy("createdAt", "desc")
      // .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach((doc) => files.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            files: files.reverse(),
            loading: false,
          });
        } else {
          this.setState({ files: [], loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribeFiles();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onEditFile = (file, originalName) => {
    const { uid, ...fileSnapshot } = file;

    this.props.firebase.filePrivateUid(file.uid).update({
      ...fileSnapshot,
      originalName,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = (uid) => {
    this.props.firebase.filePrivateUid(uid).delete();
  };

  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
  };

  handleOnChange(event, authUser) {
    const file = event.target.files[0];
    var fileExtension = getFileExtension(file.name);
    const storageRef = this.props.firebase.filePrivate(
      `${uuidv4()}.${fileExtension}`
    );
    const task = storageRef.put(file);
    this.setState({
      name: file.name,
    });

    task.on(
      "state_changed",
      (snapshot) => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage,
        });
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.setState({
              picture: url,
            });
            this.props.firebase.files().add({
              file: url,
              originalName: this.state.name,
              extension: getFileExtension(this.state.name),
              userId: authUser.uid,
              createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
          });
        task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
      }
    );
  }

  render() {
    const { loading, files } = this.state;

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            {!files && <div>Aún no has subido ningún fichero</div>}

            <hr />
            <h4>Documentación Privada</h4>

            {loading && <div>Loading ...</div>}

            {files.length > 0 && (
              <FileList
                authUser={authUser}
                files={files}
                onEditFile={this.onEditFile}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!files.length > 0 && (
              <div class="alert alert-info" role="alert">
                Es despacho aun no ha subido ninguna documentación.
              </div>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(DocumentosPrivados);
