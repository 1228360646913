import React from "react";
import { compose } from "recompose";
import { Switch, Route, Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {
  withAuthorization,
  withEmailVerification,
  AuthUserContext,
} from "../Session";
import Documentos from "../Documentos";

import FontAwesome from "react-fontawesome";
import DocumentosPrivados from "../DocumentosPrivados";
const HomePage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <main>
        <section className="section section-lg section-shaped pb-250">
          <div className="shape shape-style-1 bg-gradient-default" />
          <div className="container pt-lg-md">
            <div className="row justify-content-center">
              <div className="container">
                <h1 className="display-3  text-white">
                  Home
                  <span>
                    Este es tu espacio personal, aquí guardaremos la
                    documentación.
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-lg pt-lg-0 mt--100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="row row-grid">
                  <div className="col-lg-6">
                    <div className="card card-lift--hover shadow border-0">
                      <div className="card-body py-5">
                        <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                          <FontAwesome name="folder-o" color="green" />
                        </div>
                        <h6 className="text-success text-uppercase">
                          Mi Documentación
                        </h6>
                        <p className="description mt-3">
                          Aquí encontrarás todos los documentos que has
                          compartido con el despacho.
                        </p>
                        <p className="description mt-3">
                          <Link
                            to={ROUTES.DOCUMENTOS}
                            className="btn btn-primary"
                          >
                            Acceder
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-lift--hover shadow border-0">
                      <div className="card-body py-5">
                        <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                          <FontAwesome name="picture-o" color="green" />
                        </div>
                        <h6 className="text-success text-uppercase">
                          Documentos Despacho
                        </h6>
                        <p className="description mt-3">
                          Aquí encontrarás documentación que el despacho a
                          compartigo contigo.
                        </p>
                        <p className="description mt-3">
                          <Link
                            to={ROUTES.IMAGENES}
                            className="btn btn-primary"
                          >
                            Acceder
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <Switch>
                  <Route
                    exact
                    path={ROUTES.DOCUMENTOS}
                    // component={Documentos}
                    component={() => <Documentos authUser={authUser} />}
                  />
                  <Route
                    exact
                    path={ROUTES.IMAGENES}
                    component={() => <DocumentosPrivados authUser={authUser} />}
                  />
                  {/* <Route exact path={ROUTES.COOPERATIVA} component={Cooperativa} /> */}
                </Switch>
              </div>
            </div>
          </div>
        </section>
      </main>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);
