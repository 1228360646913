import React, { Component } from 'react';

class FileItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.file.text,
    };
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.file.text,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditMessage(this.props.file, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, file, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;

    return (
      <li>
        {editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
          <span>
            <strong>{file.userId}</strong> {file.text}
            {file.editedAt && <span>(Editado)</span>}
          </span>
        )}

        {authUser.uid === file.userId && (
          <span>
            {editMode ? (
              <span>
                <button onClick={this.onSaveEditText}>Guardar</button>
                <button onClick={this.onToggleEditMode}>
                  Limpiar
                </button>
              </span>
            ) : (
              <button onClick={this.onToggleEditMode}>Editar</button>
            )}

            {!editMode && (
              <button
                type="button"
                onClick={() => onRemoveMessage(file.uid)}
              >
                Borrar
              </button>
            )}
          </span>
        )}
      </li>
    );
  }
}

export default FileItem;
