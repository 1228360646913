import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <div>
        <main>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default" />
            <div className="container pt-lg-md">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="card bg-secondary shadow border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                      <div className="text-center mb-4">
                        <h1 className="h1Box">Recuperar contraseña</h1>
                      </div>
                      <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                          <div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="ni ni-lock-circle-open" />
                              </span>
                            </div>
                            <input
                              className="form-control"
                              name="email"
                              value={this.state.email}
                              onChange={this.onChange}
                              type="text"
                              placeholder="Correo electrónico"
                            />
                          </div>
                        </div>
                        <div className="submit">
                          <button
                            className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0 btn-primary"
                            disabled={isInvalid}
                            type="submit"
                          >
                            Resetear mi contraseña
                          </button>
                        </div>

                        {error && <p>{error.message}</p>}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
  <div className="col-6">
    <Link to={ROUTES.PASSWORD_FORGET}>
      <small>¿Has perdido la contraseña?</small>
    </Link>
  </div>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
