import React, { Component } from "react";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
  withAuthentication,
} from "../../Session";
import { withFirebase } from "../../Firebase";
import FileList from "./FileList";
import { getFileExtension } from "../../../lib/tools";
import { v4 as uuidv4 } from "uuid";
import "./documentos.css";
// import Dropzone from "react-dropzone";
import { compose } from "recompose";
class DocumentosPrivados extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
      messages: [],
      files: [],
      limit: 5,
      uploadValue: 0,
      submitting: false
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    let value = this.context;
    console.log(value);
    this.onListenForFiles();
  }

  onListenForFiles = () => {
    this.setState({ loading: true });
    
    const {userId} = this.props;
    this.unsubscribeFiles = this.props.firebase.filesPrivate()
      // .where("userId", "==", "df961zlcdif63BSykrBB0FGrGDZ2")
      .where("userId", "==", userId)
      .orderBy("createdAt", "desc")
      // .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach((doc) => files.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            files: files.reverse(),
            loading: false,
          });
        } else {
          this.setState({ files: [], loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribeFiles();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onEditFile = (file, originalName) => {
    const { uid, ...fileSnapshot } = file;

    this.props.firebase.filePrivateUid(file.uid).update({
      ...fileSnapshot,
      originalName,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = (uid) => {
    this.props.firebase.filePrivateUid(uid).delete();
  };


  handleOnChange(event) {
    this.setState({ submitting: true });
    const {userId} = this.props;
    const file = event.target.files[0];
    var fileExtension = getFileExtension(file.name);
    const storageRef = this.props.firebase.filePrivate(`${uuidv4()}.${fileExtension}`);
    const task = storageRef.put(file);
    this.setState({
      name: file.name,
    });

    task.on(
      "state_changed",
      (snapshot) => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage,
        });
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.setState({
              picture: url,
            });
            this.props.firebase.filesPrivate().add({
              file: url,
              originalName: this.state.name,
              extension: getFileExtension(this.state.name),
              userId: userId,
              createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
          })
          .then(() => {
            this.setState({ submitting: false });
          });
        task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
      }
    );
  }

  render() {
    const { submitting, messages, loading, files } = this.state;
    ;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            {loading && <div>Loading ...</div>}

            {!files && <div>Aún no has subido ningún fichero</div>}

            <hr />
            <h4>Documentación Privada</h4>
            <div>
      
              <br />
       
               <input
                type="file"
                id="filePrivate"
                onChange={(event) => this.handleOnChange(event, authUser)}
              />
              <label htmlFor="filePrivate">Subir Fichero </label>
              {submitting && (
                <progress value={this.state.uploadValue} max="100">
                  {this.state.uploadValue} %
                </progress>
              )}

              <br />
              <img width="90" src={this.state.picture} />
            </div>
            {loading && <div>Loading ...</div>}

            {messages && (
              <FileList
                files={files}
                onEditFile={this.onEditFile}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!messages && <div>No hay mensajes ...</div>}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(DocumentosPrivados);
// export default compose(withFirebase, withAuthentication)(DocumentosPrivados);
