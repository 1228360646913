import React from "react";
import { Switch, Route } from "react-router-dom";
import { compose } from "recompose";

import { withAuthorization, withEmailVerification } from "../Session";
import { UserList, UserItem, UserEdit, UserAdd, UserImport } from "../Users";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";
import {HeaderGradient} from "../../common/Layout/HeaderGradient";
import {Container} from "../../common/Layout/Container";

const AdminPage = () => (
  <div>
    <main>
      <HeaderGradient background="admin">
        <Container>
          <h1 className="display-3  text-white">
            Usuarios:
            <span>Solo para administradores</span>
          </h1>
        </Container>
      </HeaderGradient>
    </main>

    <Switch>
      <Route exact path={ROUTES.USUARIOS_ADD} component={UserAdd} />
      <Route exact path={ROUTES.USUARIOS_IMPORT} component={UserImport} />
      <Route exact path={ROUTES.USUARIOS_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.USUARIOS} component={UserList} />
      <Route exact path={ROUTES.USUARIOS_EDIT} component={UserEdit} />
    </Switch>
  </div>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminPage);
