import React, { Component } from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification
} from "../Session";
import { withFirebase } from "../Firebase";
import { PasswordForgetForm } from "../PasswordForgetAccount";
import PasswordChangeForm from "../PasswordChange";

const SIGN_IN_METHODS = [
  {
    id: "password",
    provider: null
  },
  {
    id: "google.com",
    provider: "googleProvider"
  },
  {
    id: "facebook.com",
    provider: "facebookProvider"
  },
  {
    id: "twitter.com",
    provider: "twitterProvider"
  }
];

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <main>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default" />
            <div className="container pt-lg-md">
              <div className="row justify-content-center">
                <div className="container">
                  <h1 className="display-3  text-white">
                    Cuenta:
                    <span>{authUser.email}</span>
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </main>

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card shadow border-0 inside-card">
                <h2>Restablecer Contraseña</h2>
                <PasswordForgetForm />
              </div>
            </div>
            <div className="col-md-6">
              <div className="card shadow border-0 inside-card">
                <h2>Cambiar contraseña</h2>
                <PasswordChangeForm />
              </div>
            </div>
          </div>
          {/* <UpdatePhotoProfile authUser={authUser} /> */}

          {/* <LoginManagement authUser={authUser} /> */}
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
);

class UpdatePhotoProfileBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadValue: 0
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event, authUser) {
    const file = event.target.files[0];
    

    const storageRef = this.props.firebase.picture(file);
    const task = storageRef.put(file);
    this.setState({
      name: file.name
    });

    task.on(
      "state_changed",
      snapshot => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage
        });
      },
      error => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            this.setState({
              picture: url
            });
            
            this.props.firebase.user(authUser.uid).update({
              ...authUser,
              avatar: url,
              editedAt: this.props.firebase.fieldValue.serverTimestamp()
            });
          });
        task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
        
      }
    );
  }
  render() {
    return (
      <div>
        <h4>Fotografñia de perfil</h4>
        <div>
          <progress value={this.state.uploadValue} max="100">
            {this.state.uploadValue} %
          </progress>
          <br />
          <input
            type="file"
            onChange={event => this.handleOnChange(event, this.props.authUser)}
          />
          <br />
          <img width="90" src={this.state.picture} />
        </div>
      </div>
    );
  }
}
const UpdatePhotoProfile = withFirebase(UpdatePhotoProfileBase);
class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null })
      )
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
      this.props.authUser.email,
      password
    );

    this.props.firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        Sign In Methods:
        <ul>
          {SIGN_IN_METHODS.map(signInMethod => {
            const onlyOneLeft = activeSignInMethods.length === 1;
            const isEnabled = activeSignInMethods.includes(signInMethod.id);

            return (
              <li key={signInMethod.id}>
                {signInMethod.id === "password" ? (
                  <DefaultLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onDefaultLoginLink}
                    onUnlink={this.onUnlink}
                  />
                ) : (
                  <SocialLoginToggle
                    onlyOneLeft={onlyOneLeft}
                    isEnabled={isEnabled}
                    signInMethod={signInMethod}
                    onLink={this.onSocialLoginLink}
                    onUnlink={this.onUnlink}
                  />
                )}
              </li>
            );
          })}
        </ul>
        {error && error.message}
      </div>
    );
  }
}

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink
}) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button type="button" onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </button>
  );

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: "", passwordTwo: "" };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: "", passwordTwo: "" });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { onlyOneLeft, isEnabled, signInMethod, onUnlink } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === "";

    return isEnabled ? (
      <button
        type="button"
        onClick={() => onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}
      >
        Deactivate {signInMethod.id}
      </button>
    ) : (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <div className="input-group input-group-alternative">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="ni ni-lock-circle-open" />
              </span>
            </div>
            <input
              className="form-control"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Nueva contraseña"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="input-group input-group-alternative">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="ni ni-lock-circle-open" />
              </span>
            </div>
            <input
              className="form-control"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirmar nueva contraseña"
            />
          </div>
        </div>

        <div className="submit">
          <button
            className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0 btn-primary"
            disabled={isInvalid}
            type="submit"
          >
            Link {signInMethod.id}
          </button>
        </div>
      </form>
    );
  }
}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
