import React from "react";
import { ContainerWrapper } from "./styles";

export function Container(props) {
  return (

      <ContainerWrapper>
        {props.children}
      </ContainerWrapper>
  
  );
}
