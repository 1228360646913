import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato');
  body {
      font-family: 'Lato', sans-serif;
  }
  h3 {
      font-size: 16px; 
      font-weight: 400;
  }
  h2 {
      font-weight: 400;
  }
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  button, select {
    text-transform: none;
  }
  li, p {
    line-height: 1.75em;
    color: #999;
}
*, *::before, *::after {
    box-sizing: border-box;
}
h1,h2,h3,h4,h5 {
  font-family: 'Muli', sans-serif!important;
}
`;


export default GlobalStyle;
