import React, { Component } from "react";
import moment from "moment";
import FileIcon, { defaultStyles } from "react-file-icon";
import FontAwesome from "react-fontawesome";
import styled from "styled-components";

class FileItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.file.originalName,
    };
  }

  onToggleEditMode = () => {
    this.setState((state) => ({
      editMode: !state.editMode,
      editText: this.props.file.originalName,
    }));
  };

  onChangeEditText = (event) => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditFile(this.props.file, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, file, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;

    return (
      <div class="task" data-index="0">
        <div class="task-header">
          <div class="left-side">
            <span class="task-title">
              {editMode ? (
                <input
                  className="input-text"
                  type="text"
                  value={editText}
                  onChange={this.onChangeEditText}
                />
              ) : (
                <span>
                  {file.originalName}
                  {file.editedAt && (
                    <span>
                      {" "}
                      <FontAwesome name="edit" color="green" />
                    </span>
                  )}
                </span>
              )}
            </span>
          </div>
          <div class="right-side">

            <div class="btn-edit-task" title="Edit task">
              {editMode ? (
                <div />
              ) : (
                <EditButton onClick={this.onToggleEditMode}>
                  <FontAwesome name="edit" color="green" />
                </EditButton>
              )}
            </div>
            <div class="btn-complete-task" title="Complete task">
              {editMode ? (
                <SaveButton onClick={this.onSaveEditText}>
                  <FontAwesome name="check" color="green" />
                </SaveButton>
              ) : (
                <div />
              )}
            </div>
            <div class="btn-remove-task" title="Remove task">
              {editMode ? (
                <CancelButton onClick={this.onToggleEditMode}>
                  <FontAwesome name="times" color="green" />
                </CancelButton>
              ) : (
                <DeleteButton
                  type="button"
                  onClick={() => onRemoveMessage(file.uid)}
                >
                  <FontAwesome name="trash" color="green" />
                </DeleteButton>
              )}
            </div>
          </div>
        </div>
        {/* <div class="task-body">
          <span class="task-description">Descargar fichero</span>
        </div> */}
        <div class="task-footer">
          <span class="task-status">
            <DownloadButton  href={file.file} target="_blank">
              Descargar fichero{" "}
              <FileIcon
                extension={file.extension}
                {...defaultStyles.docx}
                size="24"
              />
            </DownloadButton>
          </span>
          <span class="task-timestamp">
            {file.createdAt && moment(file.createdAt.toDate()).format("LL")}
          </span>
        </div>
      </div>
    );
  }
}

export default FileItem;

const DeleteButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #d9534f;
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
const EditButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: rgb(0, 0, 0);
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
const DownloadButton = styled.a`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: rgb(0, 0, 0);
  padding: 11px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
  &:hover {
    color: rgba(255, 255, 255, .8);
  }
`;
const SaveButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #5cb85c;
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
const CancelButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: rgb(0, 0, 0);
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
