import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconUser from "../../stylesheets/assets/icons/user.svg";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import FontAwesome from "react-fontawesome";

import {
  ListWrapper,
  UsersWrapper,
  User,
  UserAvatar,
  UserTitle,
  UserContainer,
  UserPhone,
  UserEmail,
  UserBtn,
  UserActions,
  ListHeader,
  CardFieldset,
  HeaderBtn
} from "./styles";
import DropdownMenu from "../../common/Buttons/Dropdown";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  
  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .users()
      .orderBy("username", "asc")
      .onSnapshot(snapshot => {
        const users = [];

        snapshot.forEach(doc => users.push({ ...doc.data(), uid: doc.id }));

        this.setState({
          users,
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const menuItems = [
      {
        label: "Añadir Usuario",
        icon: "fa fa-user-plus",
        onClick: () => this.props.history.push(ROUTES.USUARIOS_ADD)
      },
      {
        label: "Importar Usuarios",
        icon: "fa fa-upload",
        onClick: () => this.props.history.push(ROUTES.USUARIOS_IMPORT)
      }
    ];
    
    const { users, loading } = this.state;
    console.log(users);
    return (
      <ListWrapper>
        <ListHeader>
          <h2>Usuarios</h2>
          
          <HeaderBtn type="button">
            <DropdownMenu items={menuItems} />
          </HeaderBtn>
        </ListHeader>
        {loading && <div>Loading ...</div>}
        <UsersWrapper>
          {users.map(user => (
            <User key={user.uid}>
              {/* Avatar */}
              <div>
              <UserAvatar>{user.username.charAt(0).toUpperCase()}</UserAvatar>
              </div>
              

              <UserContainer>
                <UserTitle>{user.username}</UserTitle>
                <div>
                  <UserEmail>{user.email}</UserEmail>
                </div>

                <UserPhone href={`tel:${user.phone}`}>{user.phone}</UserPhone>
                <span className="designation">
                  {user.roles.ADMIN === "ADMIN" && (
                    <span className="badge badge-success">Admin</span>
                  )}
                </span>
              </UserContainer>
              <UserActions>
                <UserBtn
                  className="btn btn-info"
                  to={{
                    pathname: `${ROUTES.USUARIOS}/${user.uid}`,
                    state: { user }
                  }}
                >
                  <FontAwesome name="eye" /> Detalles
                </UserBtn>
              </UserActions>
            </User>
          ))}
        </UsersWrapper>
      </ListWrapper>
    );
  }
}

export default withFirebase(UserList);
