export function isValidEmail (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function isValidPasswordStrength (password) {
  var minimalPasswordRegex = new RegExp(
    '^(?=.{7,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$',
    'g'
  )
  return minimalPasswordRegex.test(password)
}
export function getFileExtension (filename) {
  return filename.split('.').pop()
}

export function isValid (value) {
  var validChars = 'trwagmyfpdxbnjzsqvhlcket'
  var dniRexp = /^[0-9]{8}[trwagmyfpdxbnjzsqvhlcket]{1}$/i
  var nieRexp = /^[XY]{1}[0-9]{7}[trwagmyfpdxbnjzsqvhlcket]{1}$/i
  var string = (value == null ? '' : value).toString().toLowerCase()

  if (!dniRexp.test(string) && !nieRexp.test(string)) return false

  var nie = string.replace(/^[x]/, '0').replace(/^[y]/, '1')

  var letter = string.substr(-1)
  var charIndex = parseInt(nie.slice(0, -1)) % 23

  return validChars.charAt(charIndex) === letter
}

export function isInt (value) {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
  )
}

// close menu bootstrap when click
export function closeNavBar () {
  const wrapper = document.getElementById('navbar_global')
  if (wrapper) {
    wrapper.classList.remove('show')
  }
}
