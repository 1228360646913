import React, { Component } from "react";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
  withAuthentication,
} from "../Session";
import { withFirebase } from "../Firebase";
import FileList from "./FileList";
import { getFileExtension } from "../../lib/tools";
import { v4 as uuidv4 } from "uuid";
import "./documentos.css";
// import Dropzone from "react-dropzone";
import { compose } from "recompose";
class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      loading: false,
      messages: [],
      files: [],
      limit: 5,
      uploadValue: 0,
      submitting: false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    let value = this.context;
    this.onListenForFiles();
  }

  onListenForFiles = () => {
    this.setState({ loading: true });

    var authUser = this.props.authUser;
    this.unsubscribeFiles = this.props.firebase
      .files()
      // .where("userId", "==", "df961zlcdif63BSykrBB0FGrGDZ2")
      .where("userId", "==", authUser.uid)
      .orderBy("createdAt", "desc")
      // .limit(this.state.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach((doc) => files.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            files: files.reverse(),
            loading: false,
          });
        } else {
          this.setState({ files: [], loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribeFiles();
  }

  onChangeText = (event) => {
    this.setState({ text: event.target.value });
  };

  onEditFile = (file, originalName) => {
    const { uid, ...fileSnapshot } = file;

    this.props.firebase.fileUid(file.uid).update({
      ...fileSnapshot,
      originalName,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = (uid) => {
    this.props.firebase.fileUid(uid).delete();
  };

  handleOnChange(event, authUser) {
    this.setState({ submitting: true });
    const file = event.target.files[0];
    var fileExtension = getFileExtension(file.name);
    const storageRef = this.props.firebase.file(`${uuidv4()}.${fileExtension}`);
    const task = storageRef.put(file);
    this.setState({
      name: file.name,
    });

    task.on(
      "state_changed",
      (snapshot) => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage,
        });
      },
      (error) => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then((snapshot) => snapshot.ref.getDownloadURL())
          .then((url) => {
            this.setState({
              picture: url,
            });
            this.props.firebase.files().add({
              file: url,
              originalName: this.state.name,
              extension: getFileExtension(this.state.name),
              userId: authUser.uid,
              createdAt: this.props.firebase.fieldValue.serverTimestamp(),
            });
          })
          .then(() => {
            this.setState({ submitting: false });
          });
        task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
      }
    );
  }

  render() {
    const { submitting, loading, files } = this.state;
    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <div>
            {loading && <div>Loading ...</div>}

            <hr />
            <h4>Documentación</h4>
            <div>
              <br />
              <input
                type="file"
                id="file"
                onChange={(event) => this.handleOnChange(event, authUser)}
              />
              <label htmlFor="file">Subir Fichero </label>
              {submitting && (
                <progress value={this.state.uploadValue} max="100">
                  {this.state.uploadValue} %
                </progress>
              )}

              <br />
            </div>
            {loading && <div>Loading ...</div>}
            {console.log(files)}
            {files.length > 0 && (
              <FileList
                authUser={authUser}
                files={files}
                onEditFile={this.onEditFile}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!files.length > 0 && (
              <div class="alert alert-info" role="alert">
                Aun no has subido ninguna documentación. Usa el botón de arriba
                para subir tu documentación.
              </div>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

// export default withFirebase(Messages)(Messages);
export default compose(withFirebase, withAuthentication)(Messages);
