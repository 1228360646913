import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const ContainerWrapper = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  width: 1024px;
  @media all and (min-width: 1408px) {
    width: 1344px;
  }
  @media all and (min-width: 1216px) {
    width: 1152px;
  }
  @media all and (max-width: 1024px) {
    width: 960px;
  }
  @media all and (max-width: 980px) {
    width: 700px;
  }
  @media all and (max-width: 736px) {
    width: 600px;
  }
  @media all and (max-width: 480px) {
    width: 400px;
  }



  .container {
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto
}

/* @media screen and (min-width:1024px) {
    .container {
        max-width: 960px
    }
    .container.is-fluid {
        margin-left: 32px;
        margin-right: 32px;
        max-width: none
    }
}

@media screen and (max-width:1215px) {
    .container.is-widescreen {
        max-width: 1152px
    }
}

@media screen and (max-width:1407px) {
    .container.is-fullhd {
        max-width: 1344px
    }
}

@media screen and (min-width:1216px) {
    .container {
        max-width: 1152px
    }
}

@media screen and (min-width:1408px) {
    .container {
        max-width: 1344px
    }
} */
`;
