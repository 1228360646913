import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import CreateUserPage from "../CreateUser";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import HomePage from "../Home";
import ComunicadoDetail from "../Comunicados/ComunicadoDetail";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import ComunicadosPage from "../ComunicadosCrud";
import { Footer } from "../../common/Footer";
import Messages from '../Messages'

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
import styled, { ThemeProvider } from "styled-components";

const theme = {
  primaryColor: "#312782",
  secondaryColor: "salmon",
  danger: "salmon",
  success: "salmon",
  warning: "salmon",
  fontFamily: "Bebas"
};

const App = () => (
  <Router>
    <ThemeProvider theme={theme}>
      <div>
        <Navigation />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route exact path={ROUTES.LANDING} component={SignInPage} />
        <Route path={ROUTES.IMPORT_USERS} component={CreateUserPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.COMUNICADO_DETAILS} component={ComunicadoDetail} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.USUARIOS} component={AdminPage} />
        <Route path={ROUTES.COMUNICADOS_LIST} component={ComunicadosPage} />
        <Route path={ROUTES.MENSAJES} component={Messages} />
        <Footer />
      </div>
    </ThemeProvider>
  </Router>
);

export default withAuthentication(App);
