import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as firebase from "firebase";
const settings = {};
const config = {
  apiKey: "AIzaSyCPhE5JtWQ9W121RlNC_PZE9U6zoAfLSUY",
  authDomain: "leader-abogados.firebaseapp.com",
  databaseURL: "https://leader-abogados.firebaseio.com",
  projectId: "leader-abogados",
  storageBucket: "leader-abogados.appspot.com",
  messagingSenderId: "843055125136"
};

class Firebase {
  constructor() {
    // app.initializeApp(config);
    firebase.initializeApp(config);

    firebase.firestore().settings(settings);

    /* Helper */

    this.fieldValue = firebase.firestore.FieldValue;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();

    /* Social Sign In Method Provider */

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection("users");

  // *** Message API ***
  message = uid => this.db.doc(`messages/${uid}`);
  messages = () => this.db.collection("messages");

  // *** Comunicados API ***
  comunicadoNuevo = uid => this.db.collection("comunicados").doc(uid);
  comunicado = uid => this.db.doc(`comunicados/${uid}`);
  comunicados = () => this.db.collection("comunicados");
  tarifaMovil = uid => this.db.doc(`tarifasMoviles/${uid}`);
  tarifasMoviles = () => this.db.collection("tarifasMoviles");
  tarifaInternet = uid => this.db.doc(`tarifasInternet/${uid}`);
  tarifasInternet = () => this.db.collection("tarifasInternet");
  preguntasFrecuentes = () => this.db.collection("preguntasFrecuentes");

  // *** Storage API ***
  file = name => this.storage.ref(`files/${name}`);
  files = () => this.db.collection("files");
  fileUid = uid => this.db.doc(`files/${uid}`);
  filePrivate = name => this.storage.ref(`files_private/${name}`);
  filesPrivate = () => this.db.collection("files_private");
  filePrivateUid = uid => this.db.doc(`files_private/${uid}`);
  picture = name => this.storage.ref(`pictures/${name}`);
  pictureUid = uid => this.db.doc(`pictures/${uid}`);
  fileUid = uid => this.db.doc(`files/${uid}`);
  pictures = () => this.db.collection("pictures");
  userFile = name => this.storage.ref(`files_user/${name}`);
  userFiles = () => this.db.collection("files_user");
}

export default Firebase;
