import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
class ComunicadosEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: "",
      title: "",
      text: "",
      category: "",
      limit: 2,
      editorState: EditorState.createEmpty(),
      loading: false,
      comunicado: null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.state.comunicado) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .comunicado(this.props.match.params.id)
      .onSnapshot(snapshot => {
        const comunicado = snapshot.data();
        const blocksFromHtml = htmlToDraft(comunicado.text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        ;
        this.setState({
          title: comunicado.title,
          text: comunicado.text,
          editorState: EditorState.createWithContent(contentState),
          category: comunicado.category,
          comunicado: snapshot.data(),
          loading: false
        });
      });
  }

  componentWillMount() {}
  componentWillUnmount() {}

  onChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  onEditComunicado = (e, comunicado) => {
    console.log(e);
    e.preventDefault();
    const { uid, ...comunicadoSnapshot } = comunicado;
    ;
    this.props.firebase.comunicado(this.props.match.params.id).update({
      ...comunicadoSnapshot,
      title: this.state.title,
      text: this.state.text,
      category: this.state.category,
      editedAt: this.props.firebase.fieldValue.serverTimestamp()
    });
  };

  onSubmit = e => {
    e.preventDefault();
    // ;
    // console.log(this.props.match.params.id);
    const { key, title, text, category } = this.state;

    const comunicadoRef = this.props.firebase.comunicado(
      this.props.match.params.id
    );
    var updateSingle = comunicadoRef.update({ title, text, category });
    // console.log(updateSingle);
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  handleChange(event) {
    // console.log("Event.target.value is", event.target.value);

    this.setState({ category: event.target.value });
  }
  render() {
    const { title, editorState, comunicado, loading } = this.state;
    // ;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <main>
              <section className="section section-shaped section-lg">
                <div className="shape shape-style-1 bg-gradient-default" />
                <div className="container pt-lg-md">
                  <div className="row justify-content-center">
                    <div className="container">
                      <h1 className="display-3  text-white">
                        Editar Comunicado:
                        <span>Editar comunicado.</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="container">
                  {loading && <div>Loading ...</div>}
                  {comunicado && (
                    <form
                      // onSubmit={this.onSubmit}
                      onSubmit={event =>
                        this.onEditComunicado(event, this.state.comunicado)
                      }
                    >
                      <div className="form-group">
                        <div className="input-group input-group-alternative mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="ni ni-hat-3" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="Título"
                            type="text"
                            value={title}
                            onChange={this.onChangeTitle}
                          />
                        </div>
                        {this.state.titleError}
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <select
                            className="select-css"
                            value={this.state.category}
                            onChange={this.handleChange}
                          >
                            <option value="Imágenes">Imágenes</option>
                            <option value="Documentos">Documentos</option>
                            <option value="Cooperativa">Cooperativa</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group input-group-alternative mb-3">
                          <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onEditorStateChange}
                            // toolbar={{
                            //   options: ["inline", "link", "emoji"],
                            //   inline: {
                            //     options: ["bold", "italic", "underline"],
                            //     bold: {
                            //       className: "bordered-option-classname"
                            //     },
                            //     italic: {
                            //       className: "bordered-option-classname"
                            //     },
                            //     underline: {
                            //       className: "bordered-option-classname"
                            //     }
                            //   }
                            // }}
                          />
                          <textarea
                            name="description"
                            disabled
                            style={{ display: "none" }}
                            value={draftToHtml(
                              convertToRaw(editorState.getCurrentContent())
                            )}
                          />
                          {this.state.textError}
                        </div>
                      </div>
                      {/* <input type="text" value={title} onChange={this.onChangeTitle} /> */}

                      <button
                        type="submit"
                        className="btn btn-info btn-icon mb-3 mb-sm-0"
                      >
                        Enviar{" "}
                      </button>
                    </form>
                  )}
                </div>
              </section>
            </main>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(ComunicadosEdit);
