import React, { Component } from "react";
import moment from "moment";
import "moment/locale/es";
import Firebase, { withFirebase } from "../Firebase";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { AuthUserContext } from "../Session";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { getFileExtension } from "../../lib/tools";
var FontAwesome = require("react-fontawesome");
const uuid = require("uuidv4");


class ComunicadoDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      comunicado: null,
      loadingFiles: false,
      files: null,
      limit: 5,
      ...props.location.state
    };
  }
  componentWillMount() {
    moment.locale("es");
  }
  componentDidMount() {
    this.onListenForPictures();
    this.onListenForFiles();
    if (this.state.comunicado) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .comunicado(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          comunicado: snapshot.data(),
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onListenForPictures = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .pictures()
      .where("comunicadoId", "==", this.props.match.params.id)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let pictures = [];
          snapshot.forEach(doc =>
            pictures.push({ ...doc.data(), uid: doc.id })
          );
          this.setState({
            pictures: pictures.reverse(),
            loadingPictures: false
          });
        } else {
          this.setState({ pictures: null, loadingPictures: false });
        }
      });
  };

  onListenForFiles = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .files()
      .where("comunicadoId", "==", this.props.match.params.id)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach(doc => files.push({ ...doc.data(), uid: doc.id }));
          this.setState({
            files: files.reverse(),
            loadingFiles: false
          });
        } else {
          this.setState({ files: null, loadingFiles: false });
        }
      });
  };

  render() {
    const {
      comunicado,
      loading,
      loadingFiles,
      files,
      loadingPictures,
      pictures
    } = this.state;

    const { firebase } = this.props;
    return (
      <div>
        <div className="shape-corto header-corto">
          <div className="container">
            
          
              {comunicado && <div>
                <h1 className="display-3  text-white">  {comunicado.title}
                <span> {comunicado.category}</span>
                </h1>
                
              </div>}
            
          </div>
        </div>
        <div className="container">
          {loading && <div>Loading ...</div>}
          {comunicado && (
            <div
              style={{
                marginTop: "40px"
              }}
            >
       
              <strong>
                {moment(comunicado.createdAt.toDate()).format("LL")}
              </strong>
              <div dangerouslySetInnerHTML={{ __html: comunicado.text }} />
              {comunicado.editedAt && <span>(Editado)</span>}
            </div>
          )}
        </div>

        <div className="container">
          <AdminPanel
            idcomunicado={this.props.match.params.id}
            firebase={firebase}
          />
        </div>
      </div>
    );
  }
}

const AdminPanel = props => (
  <AuthUserContext.Consumer>
    {authUser =>
      // !!authUser.roles[ROLES.ADMIN] && (
      //   <div>
      //     <PicturesAuth
      //       authUser={authUser}
      //       firebase={props.firebase}
      //       comunicado={props.idcomunicado}
      //     />
      //   </div>
      // )
      !!authUser.roles[ROLES.ADMIN] ? (
        <div>
          <PicturesAuth
            authUser={authUser}
            firebase={props.firebase}
            comunicado={props.idcomunicado}
          />
          <Pictures
            authUser={authUser}
            firebase={props.firebase}
            comunicado={props.idcomunicado}
          />
          <FilesAuth
            authUser={authUser}
            firebase={props.firebase}
            comunicado={props.idcomunicado}
          />
          <Files
            authUser={authUser}
            firebase={props.firebase}
            comunicado={props.idcomunicado}
          />
        </div>
      ) : (
        <div>
          <Pictures
            authUser={authUser}
            firebase={props.firebase}
            comunicado={props.idcomunicado}
          />
          <Files
            authUser={authUser}
            firebase={props.firebase}
            comunicado={props.idcomunicado}
          />
        </div>
      )
    }
  </AuthUserContext.Consumer>
);

class PicturesAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadValue: 0
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event, authUser) {
    const file = event.target.files[0];
    var ext = getFileExtension(file.name);
    var newName = uuid() + "." + ext;
    const storageRef = this.props.firebase.picture(newName);
    const task = storageRef.put(file);
    this.setState({
      name: newName
    });

    task.on(
      "state_changed",
      snapshot => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage
        });
      },
      error => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            this.setState({
              picture: url
            });
            this.props.firebase.pictures().add({
              file: url,
              name: this.state.name,
              comunicadoId: this.props.comunicado,
              userId: authUser.uid,
              createdAt: this.props.firebase.fieldValue.serverTimestamp()
            });
          });
        task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
      }
    );
  }

  render() {
    const { authUser } = this.props;
    return (
      <div>
        <progress value={this.state.uploadValue} max="100">
          {this.state.uploadValue} %
        </progress>
        <br />
        <input
          id="image"
          name="image"
          type="file"
          ref={ref => (this.uploadImage = ref)}
          style={{ display: "none" }}
          onChange={event => this.handleOnChange(event, authUser)}
        />

        <button
          className="btn btn-info btn-icon mb-3 mb-sm-0"
          onClick={e => this.uploadImage.click()}
        >
          Subir imagen <FontAwesome name="image" />
        </button>
        {/* <input
          type="file"
          onChange={event => this.handleOnChange(event, authUser)}
        /> */}
        <br />
        <img width="90" src={this.state.picture} />
      </div>
    );
  }
  ;
}

class FilesAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadValue: 0
    };
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event, authUser) {
    const file = event.target.files[0];
    var ext = getFileExtension(file.name);
    var newName = uuid() + "." + ext;
    const storageRef = this.props.firebase.file(newName);
    const task = storageRef.put(file);
    this.setState({
      name: newName,
      originalName: file.name
    });

    task.on(
      "state_changed",
      snapshot => {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({
          uploadValue: percentage
        });
      },
      error => {
        console.error(error.message);
      },
      () => {
        // Upload complete
        task
          .then(snapshot => snapshot.ref.getDownloadURL())
          .then(url => {
            this.setState({
              file: url
            });
            this.props.firebase.files().add({
              file: url,
              name: this.state.name,
              originalName: this.state.originalName,
              comunicadoId: this.props.comunicado,
              userId: authUser.uid,
              createdAt: this.props.firebase.fieldValue.serverTimestamp()
            });
          });
        task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log("Url de descarga ", downloadURL);
        });
      }
    );
  }

  render() {
    const { authUser } = this.props;
    return (
      <div>
        <progress value={this.state.uploadValue} max="100">
          {this.state.uploadValue} %
        </progress>
        <br />
        <input
          id="file"
          name="file"
          type="file"
          ref={ref => (this.uploadFile = ref)}
          style={{ display: "none" }}
          onChange={event => this.handleOnChange(event, authUser)}
        />

        <button
          className="btn btn-info btn-icon mb-3 mb-sm-0"
          onClick={e => this.uploadFile.click()}
        >
          Subir Fichero <FontAwesome name="cloud-upload" />
        </button>
        {/* <input
          type="file"
          onChange={event => this.handleOnChange(event, authUser)}
        /> */}
        <br />
        {this.state.file}
      </div>
    );
  }
}

class Pictures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingPictures: false,
      pictures: null
    };
    this.onRemovePicture = this.onRemovePicture.bind(this);
  }

  onRemovePicture = picture => {
    confirmAlert({
      title: "Confirmar eliminar",
      message: "¿Estás seguro que quieres borrar esta imagen?.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // delete doc
            this.props.firebase.pictureUid(picture.uid).delete();
            // Delete the file
            var storageRef = this.props.firebase.picture(picture.name);
            storageRef
              .delete()
              .then(function() {
                console.log("bien");
              })
              .catch(function(error) {
                console.log("Uh-ohhhhhhhh");
              });
          }
        },
        {
          label: "No",
          onClick: () =>
            console.log(
              "Click No... todos tenemos derechos a una segunda oportunidad"
            )
        }
      ]
    });
  };

  componentDidMount() {
    this.onListenForPictures();
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }
  onListenForPictures = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .pictures()
      .where("comunicadoId", "==", this.props.comunicado)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let pictures = [];
          snapshot.forEach(doc =>
            pictures.push({ ...doc.data(), uid: doc.id })
          );
          this.setState({
            pictures: pictures.reverse(),
            loadingPictures: false
          });
        } else {
          this.setState({ pictures: null, loadingPictures: false });
        }
      });
  };
  render() {
    const { authUser } = this.props;
    const { pictures, loadingPictures } = this.state;
    return (
      <div>
        {loadingPictures && <div>Loading imágenes</div>}

        {pictures && (
          <div className="">
            <div className="row">
              {pictures.map((picture, i) => (
                <div className="col-lg-4" key={i}>
                  <div />
                  <img src={picture.file} alt="" className="w100" />
                  {!!authUser.roles[ROLES.ADMIN] ? (
                    <div className="deleteImg">
                      <button
                        className="btn btn-danger miniIcons"
                        onClick={() => {
                          this.onRemovePicture(picture);
                        }}
                      >
                        <FontAwesome name="trash" />
                      </button>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingFiles: false,
      files: null
    };
    this.onRemoveFile = this.onRemoveFile.bind(this);
  }

  onRemoveFile = file => {
    confirmAlert({
      title: "Confirmar eliminar",
      message: "¿Estás seguro que quieres borrar este fichero?.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // delete doc
            this.props.firebase.fileUid(file.uid).delete();
            // Delete the file
            var storageRef = this.props.firebase.file(file.name);
            storageRef
              .delete()
              .then(function() {
                console.log("bien");
              })
              .catch(function(error) {
                console.log("Uh-ohhhhhhhh");
              });
          }
        },
        {
          label: "No",
          onClick: () =>
            console.log(
              "Click No... todos tenemos derechos a una segunda oportunidad"
            )
        }
      ]
    });
  };

  componentDidMount() {
    this.onListenForPictures();
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }
  onListenForPictures = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .files()
      .where("comunicadoId", "==", this.props.comunicado)
      .orderBy("createdAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach(doc => files.push({ ...doc.data(), uid: doc.id }));
          this.setState({
            files: files.reverse(),
            loadingFiles: false
          });
        } else {
          this.setState({ files: null, loadingFiles: false });
        }
      });
  };
  render() {
    const { authUser } = this.props;
    const { files, loadingFiles } = this.state;
    return (
      <div>
        {loadingFiles && <div>Loading archivos</div>}

        {files && (
          <div className="">
            <div className="row">
              <h1>Ficheros adjuntos</h1>
              <hr />
              <ul className="list-files">
                {files.map((file, i) => (
                  <li className="col-lg-12" key={i}>
                    <div>
                      <span className="circleIcon">
                        <FontAwesome name="file" color="green" />
                      </span>

                      <a href={file.file} target="_blank">
                        {file.originalName}
                      </a>
                    </div>

                    {!!authUser.roles[ROLES.ADMIN] ? (
                      <div className="deleteFile">
                        <button
                          className="btn btn-info miniIcons"
                          onClick={() => {
                            this.onEditFile(file);
                          }}
                        >
                          <FontAwesome name="edit" />
                        </button>
                        <button
                          className="btn btn-danger miniIcons"
                          onClick={() => {
                            this.onRemoveFile(file);
                          }}
                        >
                          <FontAwesome name="trash" />
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withFirebase(ComunicadoDetail);
