import React from 'react'

import FileItem from './FileItem'

const MessageList = ({
  authUser,
  files,
  onEditMessage,
  onRemoveMessage
}) => (
  <ul>
    {files.map(file => (
      <FileItem
        authUser={authUser}
        key={file.uid}
        file={file}
        // onRemoveMessage={onRemoveMessage}
      />
    ))}
  </ul>
)

export default MessageList
