import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Section = styled.section`
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: relative;
  /* background: ${props => props.theme.primaryColor}; */

/* background: ${props => props.background ? '100%' : '500px'}; */
  background: ${props =>
    (props.background === "section" && "-webkit-linear-gradient(3deg, #312782 0, #1a174d 100%) !important" ) ||
    (props.background === "admin" && "-webkit-linear-gradient(3deg, #312782 0, #207fad 100%) !important" ) ||
    (props.background === "warning" && "yellow")};
  /* background: ${props =>
    (props.background === "full" && "-webkit-linear-gradient(3deg, #312782 0, #1a174d 100%) !important" ) ||
    (props.background === "section" && "#000") ||
    (props.background === "warning" && "yellow")}; */
`;
