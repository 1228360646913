import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const SignUpPage = () => (
  <div>
    <main>
      <section className="section section-shaped section-lg">
        <div className="shape shape-style-1 bg-gradient-default" />
        <div className="container pt-lg-md">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card bg-secondary shadow border-0">
                <div className="card-body px-lg-5 py-lg-5">
                  <div className="text-center mb-4">
                    <h1 className="h1Box">Crear usuario</h1>
                  </div>
                  <SignUpForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  phone: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
Ya existe una cuenta con esta dirección de correo electrónico. Trate de iniciar sesión con esta cuenta en su lugar. Si crees que el la cuenta ya se usa desde uno de los inicios de sesión sociales, intente para iniciar sesión con uno de ellos. Después, asocia tus cuentas. en su página de cuenta personal.
`;

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, phone, passwordOne, isAdmin } = this.state;
    const roles = {};

    if (isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set(
          {
            username,
            email,
            phone,
            roles
          },
          { merge: true }
        );
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        // this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
        console.log({ ...INITIAL_STATE });
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      username,
      email,
      phone,
      passwordOne,
      passwordTwo,
      isAdmin,
      error
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";

    return (
      <div className="container">
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                placeholder="Nombre"
                type="text"
                name="username"
                value={username}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="email"
                value={email}
                onChange={this.onChange}
                type="text"
                placeholder="Dirección de email"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="phone"
                value={phone}
                onChange={this.onChange}
                type="text"
                placeholder="Teléfono"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Contraseña"
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group input-group-alternative mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="ni ni-hat-3" />
                </span>
              </div>
              <input
                className="form-control"
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Confirmar contraseña"
              />
            </div>
          </div>


         
          <button
            disabled={isInvalid}
            type="submit"
            className="btn btn-primary my-4"
          >
            Crear usuario
          </button>

          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}

const SignUpLink = () => (
  <div className="col-6 text-right">
    <Link to={ROUTES.SIGN_UP}>
      <small>Crear nueva cuenta</small>
    </Link>
  </div>
);




const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };
