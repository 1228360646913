export const LANDING = '/'
export const SIGN_UP = '/signup'
export const IMPORT_USERS = '/importar-usuarios'
export const SIGN_IN = '/'
export const HOME = '/home'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const USUARIOS = '/usuarios'
export const USUARIOS_ADD = '/usuarios/add'
export const USUARIOS_IMPORT = '/usuarios/import'
export const USUARIOS_DETAILS = '/usuarios/:id'
export const USUARIOS_EDIT = '/usuarios/:id/edit'
export const COMUNIDADOS = '/comunicado'
export const COMUNICADO_DETAILS = '/comunicado/:id'
export const COMUNICADO_ADD = '/comunicados/add'
export const COMUNICADO_EDIT = '/comunicados/edit/:id'
export const COMUNICADOS_LIST = '/comunicados'
export const DOCUMENTOS = '/home/documentos'
export const IMAGENES = '/home/documentos-despacho'
export const COOPERATIVA = '/home/cooperativa'
export const MENSAJES = '/mensajes'
