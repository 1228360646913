import React from "react";

import FileItem from "./FileItem";

const MessageList = ({ authUser, files, onEditFile, onRemoveMessage }) => (
  <div className="tasks">
      {files.map((file) => (
        <FileItem
          authUser={authUser}
          key={file.uid}
          file={file}
          onEditFile={onEditFile}
        onRemoveMessage={onRemoveMessage}
          // onRemoveMessage={onRemoveMessage}
        />
      ))}
    </div>
);

export default MessageList;
