import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import axios from "axios";
import Accordion from "../../common/Accordion/Accordion";
import AccordionItem from "../../common/Accordion/AccordionItem";
import { HashLink } from "react-router-hash-link";
import ReactDOM from "react-dom";
import Modal from "react-modal";
// import { getFileExtension } from "../../lib/tools";
import AOS from "aos";
import "aos/dist/aos.css";
import homeBackground from "../../stylesheets/assets/background/home.jpg";
import nocturnaBackground from "../../stylesheets/assets/background/nocturna.jpg";
import servidoresBackground from "../../stylesheets/assets/background/servidores.jpg";
import { isValid, isInt } from "../../lib/tools";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import "moment/locale/es";
import "react-dates/lib/css/_datepicker.css";
import TarifasMovilesList from "./TarifasMovilesList";
import TarifasInternetList from "./TarifasInternetList";
import Slider from "react-slick";

// import "../../../node_modules/slick-carousel/slick/slick.css";
// import "../../../node_modules/slick-carousel/slick/slick-theme.css";
moment.locale("es-ES");

var FontAwesome = require("react-fontawesome");
var homeStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  overflow: "hidden",
  zIndex: -1,
  width: "100%",
  backgroundImage: `url(${homeBackground})`
};
const poolStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center center",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  overflow: "hidden",
  zIndex: -1,
  width: "100%",
  backgroundImage: `url(${nocturnaBackground})`
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
};

window.addEventListener(
  "resize",
  function() {
    // use ".addEventListener", not ".onresize"
    checkOnResize();
  },
  true
);

function checkOnResize() {
  if (window.innerWidth > 1100) {
    console.log("desktop mode");
  } else {
    console.log("mobile mode");
  }
}
Modal.setAppElement(document.getElementById("root"));
class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openEquipo: true,
      modalIsOpen: false,
      loading: false,
      tarifasMoviles: [],
      loadingFaq: false,
      faq: [],
      tarifasInternet: [],
      loadingTarifasInternet: false
    };
    this.toggleExpandedTicket = this.toggleExpandedTicket.bind(this);
    this.onClick = this.onClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.onListenFortarifasMoviles();
    this.onListenFortarifasInternet();
    this.onListenForFaq();
  }

  onListenFortarifasMoviles = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .tarifasMoviles()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let tarifasMoviles = [];
          snapshot.forEach(doc =>
            tarifasMoviles.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            tarifasMoviles: tarifasMoviles.reverse(),
            loading: false
          });
        } else {
          this.setState({ tarifasMoviles: null, loading: false });
        }
      });
  };
  onListenFortarifasInternet = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .tarifasInternet()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let tarifasInternet = [];
          snapshot.forEach(doc =>
            tarifasInternet.push({ ...doc.data(), uid: doc.id })
          );

          this.setState({
            tarifasInternet: tarifasInternet.reverse(),
            loading: false
          });
        } else {
          this.setState({
            tarifasInternet: null,
            loadingTarifasInternet: false
          });
        }
      });
  };
  onListenForFaq = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .preguntasFrecuentes()
      .orderBy("orden", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let faq = [];
          snapshot.forEach(doc => faq.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            faq: faq.reverse(),
            loadingFaq: false
          });
        } else {
          this.setState({
            faq: null,
            loadingFaq: false
          });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentWillMount() {
    AOS.init();
  }
  toggleExpandedTicket() {
    this.setState({ openEquipo: !this.state.openEquipo });
  }
  onClick = event => {
    console.log(event);
    this.setState({ producto: event, modalIsOpen: true });
  };
  // modal
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const {
      loading,
      tarifasMoviles,
      loadingTarifasInternet,
      tarifasInternet,
      loadingFaq,
      faq
    } = this.state;
    return (
      <div>
        <main>
          <div className="position-relative">
            <Slider {...settings}>
              <div>
                <section className="section section-lg section-shaped pb-250 full-height">
                  <div className="row">
                    <div className="container py-lg-md d-flex">
                      <div className="col">
                        <div className="row">
                          <div className="col px-0">
                            <div className="row">
                              <div className="col-lg-6">
                                <h1 className="display-3  text-white">
                                  Mirador del Retín
                                  <span>Promoción en Zahara de los Atunes</span>
                                </h1>
                                <p className="lead-2  text-white">
                                  Ubicado en Zahara de los Atunes, un hermoso
                                  enclave de la costa de la luz. Sol, arena fina
                                  y blanca y aguas cristalinas convierten este
                                  pequeño rincon en uno de los lugares más
                                  exclusivos de España.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={poolStyle}>
                      <div
                        className="swiper-footer aos-init"
                        data-aos="f-out"
                        data-aos-anchor-placement="top-center"
                      >
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col text-center">
                              <div className="mouse" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </Slider>

            <section className="section section-lg pt-lg-0 mt--200">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="row row-grid">
                      <div className="col-lg-4">
                        <div className="card card-lift--hover shadow border-0">
                          <div className="card-body py-5">
                            <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                              <FontAwesome name="info-circle" color="green" />
                            </div>
                            <h6 className="text-success text-uppercase">
                              Quienes Somos
                            </h6>
                            <p className="description mt-3">
                              <b>Primavera Promociones Inmobiliarias</b>, somos
                              un equipo de profesionales que aúnan formación y
                              experiencia, en los diferentes campos de
                              conocimiento del sector inmobiliario y la
                              construcción.
                              <br />
                              Creemos en la excelencia del servicio mediante la
                              transparencia, el compromiso y el sentido común.
                              Adaptándonos a un mercado cada vez más globalizado
                              y competitivo.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-lift--hover shadow border-0">
                          <div className="card-body py-5">
                            <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                              <FontAwesome name="tree" color="green" />
                            </div>
                            <h6 className="text-success text-uppercase">
                              Nuestro Compromiso
                            </h6>
                            <p className="description mt-3">
                              Nuestro compromiso pasa por ofrecer un servicio de
                              calidad, que transmita confianza y seguridad.
                              <br />
                              Mantener una actitud proactiva y positiva en un
                              sector que trata de adaptarse a los nuevos
                              tiempos. <br />
                              Demostrar que se pueden ofrecer servicios
                              inmobiliarios de calidad que estén al alcance de
                              todos los presupuestos.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="card card-lift--hover shadow border-0">
                          <div className="card-body py-5">
                            <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                              <FontAwesome name="handshake-o" color="green" />
                            </div>
                            <h6 className="text-success text-uppercase">
                              Nuestros Colaboradores
                            </h6>
                            <p className="description mt-3">
                              PUERTO ZAHARA.
                              <br />
                              <br />
                              Empresa pionera en la{" "}
                              <a href="https://puertozahara.com/es/ventas-zahara-de-los-atunes">
                                venta de inmuebles en Zahara de los Atúnes
                              </a>
                              , Cádiz. <br />
                              <br />
                              Sus 30 años de experiencia en el sector
                              inmobiliario y las miles de familias satisfechas
                              son su principal garantía.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <section className="section section-lg" id="preguntas-frecuentes">
              <div className="container container p-0 mb-5">
                <div className="row justify-content-center">
                  <div className="col-md-12 text-center align-items-center">
                    <h2 className="eyebrow d-block mb-1">Déjanos un mensaje</h2>
                    <p className="lead text-black">
                      Rellena el formulario para dejarnos tus datos de contacto
                    </p>
                  </div>
                </div>
                <Contactar />
              </div>
            </section>

            {/* <Mailing /> */}
          </div>
        </main>
      </div>
    );
  }
}

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};


class Contactar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      apellidos: "",
      email: "",
      telefono: "",
      message: "",
      errors: {
        nombre: "",
        apellidos: "",
        email: ""
      }
    };
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "nombre":
        errors.nombre =
          value.length < 5 ? "Nombre debe contener al menos 5 caracteres!" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email no es válido!";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      console.info("Valid Form");
      // /api/send-mail
      let data = {
        name: this.state.nombre,
        email: this.state.email,
        phone: this.state.telefono,
        message: this.state.message
      };

      axios
        .post("https://primaverapi.com/api/send-mail", data)
        .then(res => {
          this.setState({
            buttonText: "Enviar otro mensaje",
            enviadoOk:
              "Su mensaje fué enviado con éxito. En breve contactaremos con usted."
          });
          this.setState({ sent: true }, this.resetForm());
        })
        .catch(() => {
          console.log("Message not sent");
        });
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="row justify-content-center">
        <div
          className="col-md-6 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay="0"
        >
          <form id="feedback-form" onSubmit={this.handleSubmit} noValidate>
            <h2>Contactar</h2>
            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Nombre"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.name}
                  name="nombre"
                  noValidate
                />
              </div>
              {errors.nombre.length > 0 && (
                <span className="error">{errors.nombre}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Correo electrónico"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  name="email"
                  noValidate
                />
              </div>
              {errors.email.length > 0 && (
                <span className="error">{errors.email}</span>
              )}
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <input
                  className="form-control"
                  placeholder="Teléfono"
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.telefono}
                  name="telefono"
                  noValidate
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-group input-group-alternative mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="ni ni-hat-3" />
                  </span>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Mensaje"
                  type="email"
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  noValidate
                />
              </div>
            </div>

            <div className="submit">
              {/* <button className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0">Create</button> */}
              <button
                type="submit"
                className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0"
              >
                ENVIAR
              </button>
            </div>
            <p>{this.state.enviadoOk}</p>
          </form>
        </div>
      </div>
    );
  }
}
export default withFirebase(Landing);
