// import React, { Component } from "react";
// import moment from "moment";
// import FileIcon, { defaultStyles } from 'react-file-icon';
// import FontAwesome from 'react-fontawesome'
// import styled from "styled-components"

// class FileItem extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       editMode: false,
//       editText: this.props.file.originalName,
//     };
//   }

//   onToggleEditMode = () => {
//     this.setState((state) => ({
//       editMode: !state.editMode,
//       editText: this.props.file.originalName,
//     }));
//   };

//   onChangeEditText = (event) => {
//     this.setState({ editText: event.target.value });
//   };

//   onSaveEditText = () => {
//     this.props.onEditFile(this.props.file, this.state.editText);

//     this.setState({ editMode: false });
//   };

//   render() {
//     const { authUser, file, onRemoveMessage } = this.props;
//     const { editMode, editText } = this.state;

//     return (
//       <tr>
//         <th scope="row"><FileIcon extension={file.extension} {...defaultStyles.docx} size="30" /></th>
//         <td>{editMode ? (
//           <input
//           className="input-text"
//             type="text"
//             value={editText}
//             onChange={this.onChangeEditText}
//           />
//         ) : (
//           <span>
//              {file.originalName}
//             {file.editedAt && <span> <FontAwesome name='edit' color='green' /></span>}
//           </span>
//         )}</td>
//         <td>{file.createdAt &&
//                     moment(file.createdAt.toDate()).format("LL")}</td>
//         <td>
//         {editMode ? (
//               <span>
//                 <SaveButton onClick={this.onSaveEditText}><FontAwesome name='check' color='green' /></SaveButton>
//                 <CancelButton onClick={this.onToggleEditMode}>
//                   Cancelar
//                 </CancelButton>
//               </span>
//             ) : (
//               <EditButton onClick={this.onToggleEditMode}><FontAwesome name='edit' color='green' /></EditButton>
//             )}
//           <DeleteButton type="button" onClick={() => onRemoveMessage(file.uid)}>
//           <FontAwesome name='trash' color='green' />
//           </DeleteButton>
//         </td>
//       </tr>
//     );
//   }
// }

// export default FileItem;

// const DeleteButton = styled.button`
//   box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
//     margin-right: 20px;
//     color: rgb(255, 255, 255);
//     font-size: 14px;
//     height: 44px;
//     position: relative;
//     display: inline-block;
//     box-sizing: border-box;
//     font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
//     font-weight: 500;
//     cursor: pointer;
//     user-select: none;
//     text-align: center;
//     background: #d9534f;
//     padding: 0px 20px;
//     border-radius: 3px;
//     border-width: initial;
//     border-style: none;
//     border-color: initial;
//     border-image: initial;
//     transition: all 0.17s ease-out 0s;
// `
// const EditButton = styled.button`
//   box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
//     margin-right: 20px;
//     color: rgb(255, 255, 255);
//     font-size: 14px;
//     height: 44px;
//     position: relative;
//     display: inline-block;
//     box-sizing: border-box;
//     font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
//     font-weight: 500;
//     cursor: pointer;
//     user-select: none;
//     text-align: center;
//     background: rgb(0, 0, 0);
//     padding: 0px 20px;
//     border-radius: 3px;
//     border-width: initial;
//     border-style: none;
//     border-color: initial;
//     border-image: initial;
//     transition: all 0.17s ease-out 0s;
// `
// const SaveButton = styled.button`
//   box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
//     margin-right: 20px;
//     color: rgb(255, 255, 255);
//     font-size: 14px;
//     height: 44px;
//     position: relative;
//     display: inline-block;
//     box-sizing: border-box;
//     font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
//     font-weight: 500;
//     cursor: pointer;
//     user-select: none;
//     text-align: center;
//     background: #5cb85c;
//     padding: 0px 20px;
//     border-radius: 3px;
//     border-width: initial;
//     border-style: none;
//     border-color: initial;
//     border-image: initial;
//     transition: all 0.17s ease-out 0s;
// `
// const CancelButton = styled.button`
//   box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
//     margin-right: 20px;
//     color: rgb(255, 255, 255);
//     font-size: 14px;
//     height: 44px;
//     position: relative;
//     display: inline-block;
//     box-sizing: border-box;
//     font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
//     font-weight: 500;
//     cursor: pointer;
//     user-select: none;
//     text-align: center;
//     background: rgb(0, 0, 0);
//     padding: 0px 20px;
//     border-radius: 3px;
//     border-width: initial;
//     border-style: none;
//     border-color: initial;
//     border-image: initial;
//     transition: all 0.17s ease-out 0s;
// `
import React, { Component } from "react";
import moment from "moment";
import FileIcon, { defaultStyles } from "react-file-icon";
import FontAwesome from "react-fontawesome";
import styled from "styled-components";

class FileItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.file.originalName,
    };
  }

  onToggleEditMode = () => {
    this.setState((state) => ({
      editMode: !state.editMode,
      editText: this.props.file.originalName,
    }));
  };

  onChangeEditText = (event) => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditFile(this.props.file, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, file, onRemoveMessage } = this.props;
    const { editMode, editText } = this.state;

    return (
      <div className="task" data-index="0">
        <div className="task-header">
          <div className="left-side">
            <span className="task-title">
              {editMode ? (
                <input
                  className="input-text"
                  type="text"
                  value={editText}
                  onChange={this.onChangeEditText}
                />
              ) : (
                <span>
                  {file.originalName}
                  {file.editedAt && (
                    <span>
                      {" "}
                      <FontAwesome name="edit" color="green" />
                    </span>
                  )}
                </span>
              )}
            </span>
          </div>
          <div className="right-side">
            {/* {editMode ? (
              <span>
                <SaveButton onClick={this.onSaveEditText}><FontAwesome name='check' color='green' /></SaveButton>
                <CancelButton onClick={this.onToggleEditMode}>
                  Cancelar
                </CancelButton>
              </span>
            ) : (
              <EditButton onClick={this.onToggleEditMode}><FontAwesome name='edit' color='green' /></EditButton>
            )} */}

            <div className="btn-edit-task" title="Edit task">
              {editMode ? (
                <div />
              ) : (
                <EditButton onClick={this.onToggleEditMode}>
                  <FontAwesome name="edit" color="green" />
                </EditButton>
              )}
            </div>
            <div className="btn-complete-task" title="Complete task">
              {editMode ? (
                <SaveButton onClick={this.onSaveEditText}>
                  <FontAwesome name="check" color="green" />
                </SaveButton>
              ) : (
                <div />
              )}
            </div>
            <div className="btn-remove-task" title="Remove task">
              {editMode ? (
                <CancelButton onClick={this.onToggleEditMode}>
                  <FontAwesome name="times" color="green" />
                </CancelButton>
              ) : (
                <DeleteButton
                  type="button"
                  onClick={() => onRemoveMessage(file.uid)}
                >
                  <FontAwesome name="trash" color="green" />
                </DeleteButton>
              )}
            </div>
          </div>
        </div>
        {/* <div className="task-body">
          <span className="task-description">Descargar fichero</span>
        </div> */}
        <div className="task-footer">
          <span className="task-status">
            <DownloadButton  href={file.file} target="_blank">
              Descargar fichero{" "}
              <FileIcon
                extension={file.extension}
                {...defaultStyles.docx}
                size="24"
              />
            </DownloadButton>
          </span>
          <span className="task-timestamp">
            {file.createdAt && moment(file.createdAt.toDate()).format("LL")}
          </span>
        </div>
      </div>
    );
  }
}

export default FileItem;

const DeleteButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #d9534f;
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
const EditButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: rgb(0, 0, 0);
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
const DownloadButton = styled.a`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: rgb(0, 0, 0);
  padding: 11px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
  &:hover {
    color: rgba(255, 255, 255, .8);
  }
`;
const SaveButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: #5cb85c;
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
const CancelButton = styled.button`
  box-shadow: rgba(32, 34, 37, 0.3) 0px 10px 20px;

  color: rgb(255, 255, 255);
  font-size: 14px;
  height: 44px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  text-align: center;
  background: rgb(0, 0, 0);
  padding: 0px 20px;
  border-radius: 3px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  transition: all 0.17s ease-out 0s;
`;
