import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { Wrapper } from "./styles";
import Documentos from "./Documentos";
import DocumentosPrivados from "./DocumentosPrivados";
class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      loadingFiles: false,
      files: null,
      limit: 5,
      ...props.location.state,
    };
  }

  componentDidMount() {
    this.onListenForPictures();
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot((snapshot) => {
        this.setState({
          user: snapshot.data(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  onListenForPictures = (user) => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .files()
      .where("userId", "==", this.props.match.params.id)
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let files = [];
          snapshot.forEach((doc) => files.push({ ...doc.data(), uid: doc.id }));

          this.setState({
            files: files.reverse(),
            loadingFiles: false,
          });
        } else {
          this.setState({ files: null, loadingFiles: false });
        }
      });
  };

  render() {
    const { user, loading, loadingFiles, files } = this.state;
  
    return (
      <Wrapper>
        <div className="container">
          {/* <h2>Usuario ({this.props.match.params.id})</h2> */}
          {/* {loading && <div>Loading ...</div>} */}

          {user && (
            <div className="row">
              <div className="col-md-6">
                <div className="card shadow border-0 inside-card">
                  <h2>{user.username}</h2>
                  <p>
                    <strong>ID:</strong> {user.uid}
                  </p>
                  <p>
                    <strong>Usuario:</strong> {user.username}
                  </p>
                  <p>
                    <strong>E-Mail:</strong> {user.email}
                  </p>
                  <p>
                    <strong>DNI:</strong> {user.dni}
                  </p>
                  <span>
                    <Link
                      to={`${ROUTES.USUARIOS}/${this.props.match.params.id}/edit`}
                      className="btn btn-block btn-primary my-4"
                    >
                      Editar usuario
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow border-0 inside-card">
                  <h3>¿Problemas con la contraseña?</h3>
                  <p>
                    Envía un correo al usuario para reestablecer su contraseña
                  </p>
                  <span>
                    <button
                      type="button"
                      className="btn btn-block btn-info my-4"
                      onClick={this.onSendPasswordResetEmail}
                    >
                      Enviar reseteo de contraseña
                    </button>
                  </span>
                </div>
              </div>
            </div>
          )}
          <hr />
          <Documentos userId={this.props.match.params.id} />
          <DocumentosPrivados userId={this.props.match.params.id} />
        </div>
      </Wrapper>
    );
  }
}

export default withFirebase(UserItem);
