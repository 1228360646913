import React, { Component } from "react";

import {
  CardWrapper,
  CardHeader,
  CardHeading,
  CardBody,
  CardFieldset,
  CardInput,
  CardIcon,
  CardButton,
} from "./styles";

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
      username: "",
      email: "",
      phone: "",
      dni: "",
      password: "",
    };

    this.revealPassword = this.revealPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  revealPassword() {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  }
  handleSubmit(event) {
    event.preventDefault();
    fetch(
      "https://us-central1-leader-abogados.cloudfunctions.net/create_user/new-user",
      {
        method: "post",
        body: {
          username: this.state.username,
          email: this.state.email,
          phone: this.state.phone,
          dni: this.state.dni,
          password: this.state.password
        },
      }
    );
  }
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <CardWrapper>
        <CardHeader>
          <CardHeading>Nuevo Usuario</CardHeading>
        </CardHeader>

        <CardBody>
          <CardFieldset>
            <CardInput
              placeholder="Nombre"
              name="name"
              type="text"
              onChange={this.onChange}
              required
            />
          </CardFieldset>

          <CardFieldset>
            <CardInput
              placeholder="E-mail"
              name="email"
              type="text"
              onChange={this.onChange}
              required
            />
          </CardFieldset>

          <CardFieldset>
            <CardInput
              placeholder="Teléfono"
              name="phone"
              type="text"
              onChange={this.onChange}
            />
          </CardFieldset>

          <CardFieldset>
            <CardInput
              placeholder="DNI"
              name="dni"
              type="text"
              onChange={this.onChange}
            />
          </CardFieldset>

          <CardFieldset>
            <CardInput
              placeholder="Contraseña"
              name="password"
              type={!this.state.isPasswordVisible ? "password" : "text"}
              onChange={this.onChange}
              required
            />

            <CardIcon
              onClick={this.revealPassword}
              className="fa fa-eye"
              eye
              small
            />
          </CardFieldset>

          <CardFieldset>
            <CardButton type="button" onClick={this.handleSubmit}>Crear Usuario</CardButton>
          </CardFieldset>
        </CardBody>
      </CardWrapper>
    );
  }
}

export default Card;
