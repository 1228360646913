import React from 'react'

import { withFirebase } from '../Firebase'

const SignOutButton = ({ firebase }) => (
  <button
    type='button'
    onClick={firebase.doSignOut}
    className='btn btn-outline-primary'
  >
    Cerrar sesión
  </button>
)

export default withFirebase(SignOutButton)
