import React from 'react'

const TarifasInternetList = ({ tarifasInternet }) => (
  <React.Fragment>
    {tarifasInternet.map((tarifaInternet, i) => (
      <div
        className='col-md-6 col-lg-4 aos-init aos-animate'
        data-aos='fade-up'
        data-aos-delay='0'
        key={i}
      >
        <article className='bordered bg-white rising p-2 p-lg-4 h-100'>
          {' '}
          <span className='eyebrow mb-2 text-gray'>Leader Abogados</span>
          <h3 className='text-black boxing'>{tarifaInternet.titulo}</h3>
          <h3 className='text-black boxing'>
            {tarifaInternet.velocidad}
            <span className='velocidadDescraga'>VELOCIDAD DE DESCARGA</span>
          </h3>
          <p>Descargas Ilimitadas</p>{' '}
          <button className='text-uppercase btn btn-sm btn-rounded btn-outline-primary mt-2'>
            Contratar {tarifaInternet.precio}
          </button>
        </article>
      </div>
    ))}
  </React.Fragment>
)

export default TarifasInternetList
