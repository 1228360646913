import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPageAccounnt = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-group">
          <div className="input-group input-group-alternative">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="ni ni-lock-circle-open" />
              </span>
            </div>
            <input
              className="form-control"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              placeholder="Dirección de Email"
            />
          </div>
        </div>
        <div className="submit">
          <button
            className="btn  btn-lg btn-block btn-icon mb-3 mb-sm-0 btn-primary"
            disabled={isInvalid}
            type="submit"
          >
            Resetear mi contraseña
          </button>
        </div>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default PasswordForgetPageAccounnt;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm };
