import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { compose } from 'recompose'

import { withAuthorization, withEmailVerification } from '../Session'
import { UserList, UserItem } from '../Users'
import ComunicadosList from '../ComunicadosCrud/ComunicadosList'
import ComunicadosAdd from '../ComunicadosCrud/ComunicadosAdd'
import ComunicadosEdit from '../ComunicadosCrud/ComunicadosEdit'
import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'

const AdminPage = () => (
  <div>
    <Switch>
      <Route exact path={ROUTES.USUARIOS_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.USUARIOS} component={UserList} />
      <Route exact path={ROUTES.COMUNICADOS_LIST} component={ComunicadosList} />
      <Route path={ROUTES.COMUNICADO_ADD} component={ComunicadosAdd} />
      <Route path={ROUTES.COMUNICADO_EDIT} component={ComunicadosEdit} />
    </Switch>
  </div>
)

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN]

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminPage)
