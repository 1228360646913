import React, { Component } from "react";

import { AuthUserContext } from "../Session";
import { withFirebase } from "../Firebase";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class ComunicadosAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      limit: 2,
      editorState: EditorState.createEmpty(),
      selectedOption: "Imágenes"
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onChangeTitle = event => {
    this.setState({ title: event.target.value });
  };

  onCreateComunicado = (event, authUser) => {
    if (!this.state.title || this.state.title.trim().length === 0) {
      this.setState({ titleError: "El campo título es obligatorio" });
      return;
    }
    if (!this.state.text || this.state.text.trim().length === 0) {
      this.setState({ textError: "El campo descripción es obligatorio" });
      return;
    }
    ;
    this.props.firebase.comunicados().add({
      title: this.state.title,
      text: this.state.text,
      category: this.state.selectedOption,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp()
    });
    this.setState({ titleError: "", textError: "" });
    this.setState({
      text: "",
      title: "",
      editorState: EditorState.createEmpty()
    });
    toast.success("Se ha añadido el comunicado!");
    event.preventDefault();
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      text: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  handleChange(event) {

    this.setState({ selectedOption: event.target.value });
  }
  render() {
    const { title, editorState } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <main>
              <section className="section section-shaped section-lg">
                <div className="shape shape-style-1 bg-gradient-default" />
                <div className="container pt-lg-md">
                  <div className="row justify-content-center">
                    <div className="container">
                      <h1 className="display-3  text-white">
                        Nuevo Comunicado:
                        <span>Crea un nuevo comunicado.</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section">
                <div className="container">
                  <form
                    onSubmit={event => this.onCreateComunicado(event, authUser)}
                  >
                    <div className="form-group">
                      <div className="input-group input-group-alternative mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="ni ni-hat-3" />
                          </span>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Título"
                          type="text"
                          value={title}
                          onChange={this.onChangeTitle}
                        />
                      </div>
                      {this.state.titleError}
                    </div>
                    <div className="form-group">
                      <div className="input-group">
                        <select
                          className="select-css"
                          value={this.state.selectedOption}
                          onChange={this.handleChange}
                        >
                          <option value="Imágenes">Imágenes</option>
                          <option value="Documentos">Documentos</option>
                          <option value="Cooperativa">Cooperativa</option>
                        </select>
                      </div>
                      {this.state.titleError}
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-alternative mb-3">
                        <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          onEditorStateChange={this.onEditorStateChange}
                          toolbar={{
                            options: ["inline", "link", "emoji"],
                            inline: {
                              options: ["bold", "italic", "underline"],
                              bold: {
                                className: "bordered-option-classname"
                              },
                              italic: {
                                className: "bordered-option-classname"
                              },
                              underline: {
                                className: "bordered-option-classname"
                              }
                            }
                          }}
                        />
                        <textarea
                          name="description"
                          disabled
                          style={{ display: "none" }}
                          value={draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )}
                        />
                        {this.state.textError}
                      </div>
                    </div>
                    {/* <input type="text" value={title} onChange={this.onChangeTitle} /> */}

                    <button
                      type="submit"
                      className="btn btn-info btn-icon mb-3 mb-sm-0"
                    >
                      Enviar{" "}
                    </button>
                  </form>
                </div>
              </section>
            </main>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(ComunicadosAdd);
